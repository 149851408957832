.registrationDefault {
  display: flex;
  justify-content: flex-end;
  min-height: 164vh;
  min-width: 900px;
  background-color: #31609e;
  overflow: hidden;
  line-height: normal !important;
  font-size: 14px;
}
.registrationDefault :global .ant-steps:not(.ant-steps-small) .ant-steps-item-title {
  margin-top: 0px !important;
}
.registrationDefault :global .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: black;
}
.registrationDefault :global .ant-steps-vertical .ant-steps-item-icon {
  margin-right: 8px;
}
.cantScan {
  font-size: 12px;
  text-align: left;
  font-weight: 100;
  height: 160px;
  line-height: 2;
}
.registrationView {
  position: relative;
  margin-right: 10%;
  padding-bottom: 150px;
  width: 360px;
  background-color: #2a3d56;
  border-right: 4px solid #3f79c6;
  border-left: 4px solid #3f79c6;
  box-sizing: content-box;
  padding: 0px 20px 0px 20px;
}
.registrationView .header {
  margin-bottom: 16px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
}
.registrationView .mfaSubtext {
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  font-size: 15px;
  text-align: center;
  color: #fff;
  line-height: 28px;
  font-weight: 300;
}
.registrationView .mfaSubtext :global .ant-steps-item-title {
  margin-top: 0px !important;
  background-color: red;
}
.registrationView .mfaSteps {
  color: white;
  font-size: 12px;
}
.registrationView .mfaTotpNote {
  font-size: 12px;
}
.registrationView .setupLater {
  font-size: 14px;
  margin-top: 2%;
  text-align: center;
  color: white;
  cursor: default;
}
.registrationView .showHideKey {
  color: deepskyblue;
  font-size: 12px;
  cursor: pointer;
}
.registrationView .mfaStepsDesc {
  margin-bottom: 20px;
  margin-top: 15px;
}
.registrationView .mfaStepDescription {
  display: flex;
  justify-content: space-between;
  width: 300px;
}
.registrationView .mfaStepsDescSubText {
  width: 400px;
  background-color: transparent;
  margin: 6px;
  height: 133px;
}
.registrationView .inputeField {
  width: 125px;
  margin-left: 55px;
}
.registrationView .verifyBtn {
  display: flex;
  margin: 35px 13px 13px 42px;
}
.registrationView .productLogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 30px 0;
}
.registrationView .productLogContainer :global(.a10-widget-appicon) svg {
  width: 64px;
  height: 64px;
}
.registrationView .productLogContainer .productName {
  margin-top: 13px;
  font-size: 20px;
  line-height: 30px;
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
}
.stepsContainer :global .ant-steps-item-tail {
  display: none !important;
}
.inputeFieldTwoFA {
  margin-left: 32%;
  width: 125px;
}
.loginTwoFa {
  margin: 9px 0px 0px 58px;
  width: 125px;
}
.haveTrouble {
  color: deepskyblue;
  margin-top: 17%;
  cursor: pointer;
  margin-bottom: 273px;
  text-align: center;
  width: 233px;
  margin-left: 82px;
}
.haveTrouble:hover {
  text-decoration: underline;
}
.otp {
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  color: #fff;
  line-height: 30px;
  font-weight: 400;
}
.headerTwofa {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 30px;
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  margin-top: 50px;
  text-align: center;
}
.a10-logo-container {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.a10-logo-container .a10-logo {
  margin-top: 45vh;
  margin-right: 195px;
  width: 162px;
  height: 60px;
  background: url('../../../../assets//images/a10_logo_white.svg') no-repeat;
  background-size: 162px 62px;
}
.validationOtpDefault {
  display: flex;
  justify-content: flex-end;
  min-height: 102vh;
  min-width: 900px;
  background-color: #31609e;
  overflow: hidden;
  line-height: normal !important;
  font-size: 14px;
}
.daysRemaining {
  color: white;
  cursor: default;
  margin-left: 6px;
}
.disableSetupLater {
  pointer-events: none;
  color: grey;
  cursor: default;
}
