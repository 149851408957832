.google-login-container {
  height: 270px !important;
  min-height: auto !important;
}
.google-login {
  margin: auto;
  background: url('../../../../assets/images/google_login.png') no-repeat;
  background-position: 0 0;
  border: 0;
  display: block;
  width: 382px;
  height: 92px;
  position: relative;
  left: -20px;
  &:active {
    background-position: 0 -92px;
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &.preload {
    display: none;
  }
}
