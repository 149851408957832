.login-default {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  min-width: 900px;
  background-color: #31609e;
  overflow: hidden;
  line-height: normal !important;
  font-size: 14px;
}
.login-default .loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.a10-logo-container {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.a10-logo-container .a10-logo {
  margin-top: 45vh;
  margin-right: 195px;
  width: 162px;
  height: 60px;
  background: url('../assets/images/a10_logo_white.svg') no-repeat;
  background-size: 162px 62px;
}
.login-view {
  position: relative;
  margin-right: 10%;
  padding-bottom: 150px;
  min-width: 400px;
  width: 400px;
  background-color: #2a3d56;
  border-right: 4px solid #3f79c6;
  border-left: 4px solid #3f79c6;
  box-sizing: content-box;
}
.login-view .form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-view .form-container .header {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 30px;
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
}
.login-view .form-container .form {
  padding: 0 20px;
  max-width: 100%;
  width: 100%;
}
.login-view .form-container .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
}
.login-view .form-container .main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 85px;
}
.login-view .form-container .bottom-content {
  margin: 20px 0 36px 0;
  padding: 0 40px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
.login-view .form-container .bottom-content .form-group {
  flex-direction: row;
}
.login-view .form-container .label {
  display: inline-block;
  padding: 0 4px;
  height: auto;
  color: #fff;
  font-size: 14px;
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  word-break: break-word;
  text-align: left;
}
.login-view .form-container input[type='text'],
.login-view .form-container input[type='password'] {
  margin-top: 4px;
  height: 32px;
  font-size: 14px;
  color: #1b1b1b;
}
.login-view .form-container input[type='checkbox'] {
  box-shadow: none;
  min-width: 20px;
  height: 20px;
  margin-right: 10px;
}
.login-view .form-container .select-component {
  margin-top: 4px;
}
.login-view .form-container .select-component > div {
  box-shadow: inset 0 1px 3px 0 #c3cad7;
  border: solid 1px #c3cde2;
  border-radius: 6px;
}
.login-view .form-container .form-control {
  border-radius: 6px;
  box-shadow: inset 0 1px 3px 0 #c3cad7;
  border: solid 1px #c3cde2;
  background-color: #ffffff;
}
.login-view .form-container .login-btn {
  background-color: #4a90e2;
  border-radius: 6px !important;
  color: #fff;
  height: 32px;
  width: 186px;
  margin-top: 20px;
}
.login-view .form-container .action-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-view .form-container .action-link {
  margin-top: 14px;
  font-size: 14px;
  color: #437dc3;
  text-decoration: none;
}
.login-view .form-container .action-link:hover {
  color: #4a90e2;
}
.login-view .form-container .navigate-link {
  color: #437dc3;
  text-decoration: none;
}
.login-view .form-container .navigate-link:hover {
  color: #4a90e2;
  cursor: pointer;
}
.login-view .form-container .validate-link {
  font-size: 16px;
  font-weight: normal;
  color: #4a90e2;
  padding-left: 5px;
}
.login-view .form-container .condition {
  word-break: break-word;
}
.login-view .form-container .add-btn,
.login-view .form-container .delete-btn {
  position: absolute;
  right: -24px;
  top: 0;
}
.login-view .form-container .add-btn svg,
.login-view .form-container .delete-btn svg {
  color: #fff;
  font-size: 16px;
}
.login-view .form-container .delete-btn + .add-btn {
  right: -45px;
}
.login-view .form-container .ant-form-item {
  margin-bottom: 16px;
  width: 100%;
}
.login-view .form-container .ant-form-item-control {
  line-height: normal;
}
.login-view .form-container .ant-input {
  border-radius: 6px;
  border: solid 1px #c3cde2;
  box-shadow: inset 0 1px 3px 0 #c3cad7;
}
.login-view .form-container .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  color: #fff;
}
.login-view .form-container .ant-radio-group .ant-radio-wrapper:first-child {
  margin-bottom: 10px;
}
.preload-google-login {
  background-image: url('../assets/images/google_login.png');
  width: 1px;
  height: 1px;
  display: none;
}
.modal-body > p:first-child::first-letter {
  font-size: 2em;
}
